<template>
  <b-card>
    <form-component
      :all-categories="allCategories"
      :main-category-data="mainCategory"
      :categories="categories"
      @onSubmit="onSubmit"
      @fetchByLocale="onFetchByLocale" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/elements/hero-section-carousel/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';

import { submit, all as allCarouselElements } from '@/http/hero-section-carousel';
import { all as allCategories } from '@/http/navigation';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      categoryGroups: [],
      mainCategory: {
        id: null,
        image: null,
        category: null,
        title: null,
        is_main: true,
        is_external_link: false,
        external_link: null,
      },
      categories: [],
    };
  },

  async created() {
    await this.fetchCategories();
  },

  computed: {
    allCategories() {
      // TODO: ?refactor?
      // Change api to /navigation/navigation-elements and get all elements
      return this.categoryGroups
        .map((group) => {
          return group.navigation_subgroups
            .map((subgroup) => {
              return subgroup.navigation_elements
                .map((element) => {
                  return {
                    name: element.name,
                    id: element.id,
                  };
                })
                .flat();
            })
            .flat();
        })
        .flat();
    },
  },
  methods: {
    async fetchCategories(lang = 'pl') {
      allCategories({ lang })
        .then(({ data }) => {
          this.categoryGroups = data.data;
        })
        .catch((error) => {
          this.showErrorNotification(
            'Problem z pobraniem danych',
            'Wystąpił błąd podczas pobierania danych. Skontaktuj się ze swoim developerem.',
          );
        })
        .finally(async () => {
          await this.fetchByLocale(lang);
        });
    },
    async onFetchByLocale(data) {
      await this.fetchCategories(data);
    },
    async fetchByLocale(data) {
      allCarouselElements(data)
        .then(({ data }) => {
          this.mainCategory = data.main_category;
          this.categories = data.categories;
        })
        .catch((error) => {
          this.showErrorNotification(
            'Problem z pobraniem danych',
            'Wystąpił błąd podczas pobierania danych. Skontaktuj się ze swoim developerem.',
          );
        });
    },
    async onSubmit(data) {
      const mappedCategories = {
        main_category: {
          id: data.mainCategory.id,
          navigation_element_id: data.mainCategory.category.id,
          title: data.mainCategory.title,
          is_main: true,
          image: data.images.image.file,
          is_external_link: data.mainCategory.is_external_link,
          external_link: data.mainCategory.external_link,
        },

        categories: data.carouselCategories.map((category) => {
          return {
            ...category,
            navigation_element_id: category.category.id,
            title: category.title,
            is_main: false,
            image: data.images[`image-${category.id}`].file,
            is_external_link: category.is_external_link,
            external_link: category.external_link,
            category_order: category.category_order,
          };
        }),
      };

      const categories = { categories: [mappedCategories.main_category, ...mappedCategories.categories] };
      const form = new FormData();

      categories.categories.forEach((category, index) => {
        if (typeof category.id !== 'string') {
          form.append(`categories[${index}][id]`, category.id);
        }

        form.append(`categories[${index}][navigation_element_id]`, category.navigation_element_id);
        form.append(`categories[${index}][title]`, category.title);
        form.append(`categories[${index}][is_main]`, category.is_main ? 1 : 0);
        form.append(`categories[${index}][image]`, category.image);
        form.append(`categories[${index}][is_main_additional]`, category.is_main_additional ? 1 : 0);
        form.append(`categories[${index}][is_external_link]`, category.is_external_link ? 1 : 0);
        form.append(`categories[${index}][external_link]`, category.external_link);
        form.append(`categories[${index}][category_order]`, category.category_order ?? null);
      });
      form.append('lang', data.lang);

      submit(form)
        .then(() => {
          this.fetchByLocale(form.get('lang'));
          this.showSuccessNotification('Dane zapisane', 'Dane zostały zapisane pomyślnie.');
        })
        .catch(() => {
          this.showErrorNotification(
            'Problem z zapisaniem danych',
            'Wystąpił błąd podczas zapisywania danych. Skontaktuj się ze swoim developerem.',
          );
        });
    },
  },
};
</script>
