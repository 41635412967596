<template>
  <validation-observer ref="heroCarouselObserver">
    <b-form @submit.prevent="validationForm" class="form-container">
      <b-col>
        <h4 class="mb-2">Wybierz język</h4>
        <b-form-group label="Język" label-for="v-language-select">
          <v-select
            id="v-language-select"
            label="value"
            placeholder="Wybierz język"
            v-model="lang"
            :clearable="false"
            :deselectFromDropdown="false"
            :options="languages"
            @input="fetchByLocale" />
        </b-form-group>
      </b-col>

      <b-col>
        <h4 class="mb-2 mt-3">Kategoria główna</h4>
        <b-form-group label="Kategoria główna">
          <validation-provider #default="{ errors }" name="Kategoria główna" rules="required">
            <v-select
              id="v-language-select"
              label="name"
              placeholder="Wybierz kategorię"
              v-model="mainCategory.category"
              :disabled="!lang"
              :clearable="false"
              :deselectFromDropdown="false"
              :options="allCategories" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Tytuł głównej kategorii" label-for="v-main-category-title">
          <validation-provider #default="{ errors }" name="Tytuł kategorii głównej" rules="required|max:150">
            <b-form-input
              v-model="mainCategory.title"
              id="v-main-category-title"
              placeholder="Tytuł głównej kategorii"
              :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-form-group class="mt-2" label="">
        <b-form-checkbox v-model="mainCategory.is_external_link"> Zewnętrzny link? </b-form-checkbox>
      </b-form-group>

      <b-form-group
        v-if="mainCategory.is_external_link"
        label="Zewnętrzny link"
        :label-for="`v-category-external-link`">
        <validation-provider #default="{ errors }" :name="`Zewnętrzny link`" rules="required">
          <b-form-input
            :id="`v-category-external-link`"
            v-model="mainCategory.external_link"
            placeholder="Zewnętrzny link"
            :disabled="!lang" />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-col>
        <b-form-group label="Zdjęcie kategorii głównej" label-for="v-image">
          <b-button @click="toggleShowModal('image')" :disabled="!lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Zdjęcie kategorii głównej" rules="required|size:5000">
            <!-- workaround for validation-provider -->
            <input v-if="files.image" type="hidden" name="Zdjęcie kategorii głównej" v-model="files.image.file" />
            <input v-else type="hidden" name="Zdjęcie kategorii głównej" v-model="files.image" />

            <ImageSelector
              v-if="showImagesModal.image"
              :files="filesArray"
              :show-modal="showImagesModal.image"
              @closeModal="toggleShowModal('image')"
              @submit="(fileList) => handleFile(fileList[0], 'image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview v-if="files.image" :image="files.image" @deleteImage="() => handleDelete('image')" />
        </b-form-group>
      </b-col>

      <!-- Form repeater -->
      <b-form-group class="w-100">
        <template #label>
          <h3 class="mb-2 mt-2">Kategorie na karuzeli</h3>
        </template>

        <b-col
          v-for="(item, index) in carouselCategories"
          :id="item.id"
          :key="item.id"
          ref="row"
          :class="`${index === 0 ? '' : 'mt-2'}`">
          <b-form-group :label="`Kategoria #${index + 1}`" label-for="v-category-select">
            <validation-provider #default="{ errors }" :name="`Kategoria #${index + 1}`" rules="required">
              <v-select
                id="v-category-select"
                label="name"
                placeholder="Wybierz kategorię"
                v-model="carouselCategories[index].category"
                :clearable="false"
                :disabled="carouselCategories[index].is_external_link"
                :deselectFromDropdown="false"
                :options="allCategories" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Tytuł kategorii" :label-for="`v-category-title-${item.id}`">
            <validation-provider #default="{ errors }" :name="`Tytuł kategorii #${index + 1}`" rules="required|max:150">
              <b-form-input
                :id="`v-category-title-${item.id}`"
                v-model="carouselCategories[index].title"
                placeholder="Tytuł kategorii"
                :disabled="!lang" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group class="mt-2" label="">
            <b-form-checkbox
              v-model="carouselCategories[index].is_main_additional"
              @change="(val) => toggleIsMainAdditional(val, index)">
              Dołączyć do kategorii głównej?
            </b-form-checkbox>
          </b-form-group>

          <b-form-group v-if="carouselCategories[index].is_main_additional" class="mt-2" label="">
            <b-form-input
              type="number"
              v-model="carouselCategories[index].category_order"
              min="1"
              placeholder="Nadaj kolejność" />
          </b-form-group>

          <b-form-group class="mt-2" label="">
            <b-form-checkbox
              v-model="carouselCategories[index].is_external_link"
              @change="(val) => toggleExternalLink(val, index)">
              Zewnętrzny link?
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            v-if="carouselCategories[index].is_external_link"
            label="Zewnętrzny link"
            :label-for="`v-category-external-link-${item.id}`">
            <validation-provider #default="{ errors }" :name="`Zewnętrzny link #${index + 1}`" rules="required">
              <b-form-input
                :id="`v-category-external-link-${item.id}`"
                v-model="carouselCategories[index].external_link"
                placeholder="Zewnętrzny link"
                :disabled="!lang" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group class="cateogry-file-container">
            <template slot="label">
              <span :label-for="`v-category-image-${item.id}`">Zdjęcie kategorii</span>
            </template>

            <b-button @click="toggleShowModal(`image-${item.id}`)" :disabled="!lang"> Wybierz zdjęcie </b-button>
            <validation-provider
              #default="{ errors }"
              :name="`Zdjęcie kategorii #${index + 1}`"
              rules="required|size:5000">
              <!-- workaround for validation-provider -->
              <input
                v-if="files[`image-${item.id}`]"
                type="hidden"
                :name="`Zdjęcie kategorii #${index + 1}`"
                v-model="files[`image-${item.id}`].file" />
              <input
                v-else
                type="hidden"
                :name="`Zdjęcie kategorii #${index + 1}`"
                v-model="files[`image-${item.id}`]" />

              <ImageSelector
                v-if="showImagesModal[`image-${item.id}`]"
                :files="filesArray"
                :show-modal="showImagesModal[`image-${item.id}`]"
                @closeModal="toggleShowModal(`image-${item.id}`)"
                @submit="(fileList) => handleFile(fileList[0], `image-${item.id}`)" />
              <small class="text-danger d-block">{{ errors[0] }}</small>
            </validation-provider>

            <ImagePreview
              v-if="files[`image-${item.id}`]"
              :image="files[`image-${item.id}`]"
              @deleteImage="() => handleDelete(`image-${item.id}`)" />
          </b-form-group>
          <!-- Remove Button -->

          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-0 mt-md-2"
            size="sm"
            :disabled="index === 0"
            @click="removeItem(index)">
            <feather-icon icon="XIcon" class="mr-25" />
            <span>Usuń</span>
          </b-button>

          <hr />
        </b-col>

        <b-col class="mb-50">
          <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-primary" @click="addCarouselCategoryItem">
            <span>Dodaj element do karuzeli</span>
          </b-button>
        </b-col>
      </b-form-group>

      <b-col class="mt-5">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" :disabled="!lang">
          Zapisz
        </b-button>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import FileUploadMixin from '@/mixins/FileUploadMixin.vue';
import NotificationMixin from '@/mixins/NotificationMixin';
import { getLanguages } from '@/constants/languages';

import ImagePreview from '@/components/shared/ImagePreview.vue';
import ImageSelector from '@/components/shared/ImageSelector.vue';

export default {
  mixins: [FileUploadMixin, NotificationMixin],
  props: {
    allCategories: {
      type: Array,
      required: true,
    },
    mainCategoryData: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  components: {
    ImagePreview,
    ImageSelector,
  },

  data() {
    return {
      languages: getLanguages(),
      lang: null,
      mainCategory: this.mainCategoryData,
      carouselCategories: [],
    };
  },

  watch: {
    async mainCategoryData(newValue) {
      if (newValue.image) {
        this.setImage(this.mainCategoryData.image, 'image');
      }

      this.mainCategory = {
        ...newValue,
        category: {
          id: newValue.navigation_element_id,
          name: this.allCategories.find((category) => category.id === newValue.navigation_element_id).name,
        },
      };
    },
    async categories(newValue) {
      for (let i = 0; i < newValue.length; i++) {
        if (newValue[i] === this.carouselCategories[i]) return;

        if (newValue[i].image) {
          this.setFileUploadData([`image-${newValue[i].id}`], [`image-${newValue[i].id}`]);
          this.setImage(newValue[i].image, `image-${newValue[i].id}`);
        }

        newValue[i] = {
          ...newValue[i],
          category: {
            id: newValue[i].navigation_element_id,
            name: this.allCategories.find((category) => category.id === newValue[i].navigation_element_id).name,
            category_order: newValue[i].category_order,
          },
        };
      }

      this.carouselCategories = newValue;
    },
  },

  async created() {
    this.lang = {
      lang: 'pl',
      value: 'Polski',
    };
    this.setFileUploadData(['image'], ['image']);
  },

  methods: {
    toggleExternalLink(value, index) {
      const categories = [...this.carouselCategories];
      categories[index].is_external_link = value;

      this.carouselCategories = categories;
    },
    toggleIsMainAdditional(value, index) {
      const categories = [...this.carouselCategories];
      categories[index].is_main_additional = value;

      this.carouselCategories = categories;
    },
    onSubmit() {
      this.$emit('onSubmit', {
        mainCategory: this.mainCategory,
        carouselCategories: this.carouselCategories,
        images: { ...this.files },
        lang: this.lang.lang,
      });
    },
    validationForm() {
      this.$refs.heroCarouselObserver.validate().then((success) => {
        if (success) {
          return this.onSubmit();
        }

        this.showFormValidationNotification();
      });
    },
    fetchByLocale() {
      this.$emit('fetchByLocale', this.lang.lang);
    },
    addCarouselCategoryItem() {
      const id = new Date().getTime();

      this.setFileUploadData([`image-${id}`], [`image-${id}`]);
      this.carouselCategories.push({
        id: id,
        image: null,
        preview: '',
        is_main: false,
        is_main_additional: false,
        is_external_link: false,
      });
    },
    removeItem(index) {
      this.carouselCategories.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
